export default function WWdo() {
  return (
    <div className="grid grid-cols-1 mt-12 ">
      <section id="whatwedo">
      <div className="text-5xl text-white mb-24"> What We Do ? </div>
      <div className="grid grid-cols-1 text-white">
        <div className=" gap-4">

          <div className="mt-10 inline-flex sm:mr-0 sm:ml-0 lg:ml-96 lg:mr-96   ">
            <img src="images/wwd1.png" style={{ maxHeight: 60 }} />
            <div className="wdo2 text-left ml-4 ">
              [Defi 1.0] Robinia Swap is a Yield Farm Defi service running on the BSC chain , and various complementary systems have been added to control token economy and inflation.
            </div>
          </div>

          <div className=" mt-10 inline-flex sm:ml-0 sm:mr-0  lg:ml-72 lg:mr-96   ">
            <img src="images/wwd2.png" style={{ maxHeight: 60 }} />
            <div className="wdo text-left ml-4 mr-16">
              [Defi 2.0] Wisteria Swap is the first Defi 2.0 model that maintains the yield farming system. It is as safe as using verified contracts , audited by Techrate , and features more than 6 deflation mechanisms.
            </div>
          </div>

          <div className=" mt-10 inline-flex sm:mr-0 sm:ml-0 lg:ml-96 lg:mr-96  ">
            <img src="images/wwd3.png" style={{ maxHeight: 60 }} />
            <div className="wdo2 text-left ml-4 ">
            [Game ] It is an economic strategy simulation SRPG genre in the background of the Middle Ages, and it can be played without downloading anywhere in the world through a fair blockchain reward system using the game money (Gold) system.
            </div>
          </div>

          <div className=" mt-10 inline-flex sm:ml-0 sm:mr-0  lg:ml-72 lg:mr-96  ">
            <img src="images/wwd4.png" style={{ maxHeight: 60 }} />
            <div className="wdo text-left ml-4 mr-16">
            [Defi 1.5] RobiniaSwap V2 is a DeFi 1.0 service, but it is a DeFi 1.5 service model that utilizes the strengths of the DeFi 2.0 service. Since the protocol is responsible for most of the liquidity supply, stable service operation is possible even if users do not proceed with the RV2 liquidity supply, a governance token.
            </div>
          </div>

          <div className=" mt-10 inline-flex sm:ml-0 sm:mr-0 lg:ml-96 lg:mr-96  ">
            <img src="images/wwd5.png" style={{ maxHeight: 60 }} />
            <div className="wdo2 text-left ml-4 ">
           [Reits] ReitsDAO is a service operated by Blokfield.Inc a decentralized real estate investment product  linked to real assets in the BSC blockchain network service.
           ReitsDAO issues "RDF" tokens composed of real estate value as collateral and "RDF" is designed to include all real estate values owned by ReitsDAO and revenue generated from real estate.
            </div>
          </div>

        </div>
      </div>
      </section>
    </div>
  );
}
